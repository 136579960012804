/*

Sample Vehicle


{
                  "@DealerCrossReferenceID": "1",
                  "@DealerId": "206568",
                  "@ModelId": "CT1B7DJW",
                  "@resultIndex": "1",
                  "@id": "1",
                  "ModelYear": "2013",
                  "ModelGroupName": "Accord Coupe",
                  "ModelTrim": "EX",
                  "ModelMarketingName": "EX",
                  "ModelTransmission": "CVT",
                  "ModelTransmissionMarketingName": "CVT",
                  "ModelBaseColor": null,
                  "ModelExteriorColorCD": null,
                  "ModelInteriorColorCD": null,
                  "ModelMSRP": "25875.00",
                  "DHCost": null,
                  "ModelVINsOnSite": {
                    "@Count": "1",
                    "VIN": [
                      {
                        "VIN": "1HGCT1B74DA022878",
                        "Mileage": "21673",
                        "DSRP": "16592.00",
                        "CertDate": "2016-11-16",
                        "ModelExteriorColorName": "ALABASTER SILVER METALLIC",
                        "ModelInteriorColorName": "BLACK",
                        "Photos": null
                      }
                    ]
                  },
                  "ModelVINsInTransit": {
                    "@Count": "0"
                  },
                  "ModelStockPhotos": {
                    "Photos": null
                  },
                  "BodyStyles": {
                    "BodyStyle": [
                      "1",
                      "5",
                      "7"
                    ]
                  },
                  "ModelAttributes": {
                    "ModelAttribute": [
                      "6",
                      "13",
                      "17",
                      "25"
                    ]
                  },
                  "Specials": null
                },


*/

/*
 * This class is used on Inventory and Vehicle details
 */
class hcpv_car {
  featureStr = "Leather Interiors|AWD / 4WD|Moonroof|Bluetooth|Android Auto|Apple CarPlay|Rearview Camera|Heated Seats|Rear Entertainment System|Navigation".split(
    "|"
  );
  featureStrMap = "INTERIOR MATERIAL|DRIVE|Moonroof|BTUTH|AAUTO|CPLAY|Rearview Camera|Heated Seats|Rear Entertainment System|NAVIG".split(
    "|"
  );

  imgNABig = "/images/vehicle-details/NIA-900x450-01.jpg";
  imgNASmall = "/images/vehicle-details/NIA-690x520-01.jpg";

  constructor(
    vin,
    stock,
    dealerId,
    dealerName,
    dealerDistance,
    modelId,
    specialLease,
    offerDescription,
    year,
    groupName,
    trim,
    trimName,
    transmission,
    transmissionName,
    msrp,
    mileage,
    dsrp,
    certDate,
    exColor,
    cpoTier,
    exColorName,
    inColor,
    photo,
    stockPhoto,
    styles,
    attributes,
    specials,
    specialAPR,
    specialTerm,
    inColorCD,
    exColorCD,
    engineName,
    vhrurl,
    iceurl,
    VehicleType,
    hasBuyOnline,
    buyOnlineURL,
    specialDescription,
    specialMonthlyPayment,
    specialEndDate,
    specialId
  ) {
    this.vin = vin;
    this.hasBuyOnline = hasBuyOnline;
    this.buyOnlineURL = buyOnlineURL;
    this.stock = stock;
    this.dealerId = dealerId;
    this.dealerName = dealerName;
    this.dealerDistance = dealerDistance;
    this.modelId = modelId;
    this.specialLease = specialLease;
    this.offerDescription = offerDescription;
    this.year = year;
    this.groupName = groupName;
    this.trim = trim;
    this.trimName = trimName;
    this.transmission = transmission;
    this.transmissionName = transmissionName;
    this.msrp = msrp;
    this.mileage = mileage;
    this.dsrp = dsrp;
    this.certDate = certDate;
    this.exColor = exColor;
    this.cpoTier = cpoTier;
    this.VehicleType = VehicleType;
    this.exColorName = exColorName;
    this.inColor = inColor;

    this.stockPhoto = stockPhoto;
    this.styles = styles;
    this.attributes = attributes;

    this.fullAttributeObj = {};
    this.fullAttributesArray = new Array();
    this.lmpg = 0;
    this.hmpg = 0;
    this.cmpg = 0;
    this.dealer = null;

    this.photos = null;
    this.photo = photo;
    if (photo !== null) {
      if (photo.Photos.Photo !== undefined) {
        this.photos = photo.Photos.Photo;
        if (photo.Photos.Photo[0] != undefined)
          this.photo = photo.Photos.Photo[0]["#text"];
      }
    }
    if (this.photo == null && stockPhoto !== undefined && stockPhoto !== null) {
      if (stockPhoto.Photos !== undefined) {
        if (stockPhoto.Photos !== null) {
          if (stockPhoto.Photos.length > 0) {
            if (stockPhoto.Photos[0].Photo !== undefined) {
              if (stockPhoto.Photos[0].Photo !== null) {
                this.photo =
                  "https://automobiles.honda.com" +
                  stockPhoto.Photos[0].Photo[0]["#text"];
                if (this.photos == null) {
                  this.photos = new Array();
                  for (var i = 0; i < stockPhoto.Photos[0].Photo.length; i++) {
                    this.photos.push({
                      "#text":
                        "https://automobiles.honda.com" +
                        stockPhoto.Photos[0].Photo[i]["#text"],
                      honda: "true"
                    });
                    // this.photos.push({"#text":"https://automobiles.honda.com"+stockPhoto.Photos[1].Photo[i]["#text"], "honda":"true"});
                  }
                }
              }
            }
          }
        }
      }
    }

    // Image Not Available
    if (this.photo == null) {
      this.photo = this.imgNASmall;
      if (this.photos == null) {
        this.photos = new Array();
        this.photos.push({ "#text": this.imgNABig, honda: "true" });
        this.photos.push({ "#text": this.imgNABig, honda: "true" });
      }
    }

    this.offers = "off";
    this.specials = null;
    if (specials.Special !== undefined) {
      this.specials = specials.Special;
      
      // temporarily suppressing offers for Used and Honda True Used vehicles
      // remove the && condition to undo
      if (this.specials.length > 0 && this.VehicleType != 'U' && this.cpoTier != 'BT') {
        this.offers = "on";
        this.specialAPR = specialAPR;
        this.specialTerm = specialTerm;
        this.specialDescription = specialDescription;
        this.specialMonthlyPayment = specialMonthlyPayment;
        this.specialEndDate = specialEndDate;
        this.specialId = specialId;
      }
    }
    if (specials != undefined && specials.Special !== undefined) {
      // temporarily suppressing offers for Used and Honda True Used vehicles 
      // remove the && condition to undo 
      if (specials["@Count"] != "0" && this.VehicleType != 'U' && this.cpoTier != 'BT') {
        this.offers = "on";
        this.specialAPR = specialAPR;
        this.specialTerm = specialTerm;
        this.specialDescription = specialDescription;
        this.specialMonthlyPayment = specialMonthlyPayment;
        this.specialEndDate = specialEndDate;
        this.specialId = specialId;
      }
    }

    this.distance = 0;
    this.style = null;
    this.seating = null;
    this.engine = null;
    this.drive = null;

    this.inColorCD = inColorCD;
    this.exColorCD = exColorCD;

    this.features = new Array();
    this.engineName = engineName;

    this.vhrsource = null;
    this.vhrurl = null;
    if (vhrurl != null) {
      if (vhrurl["@Source"] != undefined) {
        this.vhrsource = vhrurl["@Source"];
        if (vhrurl["#text"] != undefined) {
          this.vhrurl = vhrurl["#text"];
        }
      } else this.vhrurl = vhrurl;
    }
    //fair Subscription
    // this.SubscriptionStatus = "off";
    // this.SubscriptionAmount = null;
    // this.Subscription = null;

    // if (Subscription != null && Subscription["@Amount"] != "") {
    //   this.SubscriptionStatus = "on";
    //   this.SubscriptionAmount = Subscription["@Amount"];
    //   this.SubscriptionUrl = Subscription["#text"];
    //   this.SubscriptionTerm = Subscription["@Term"];
    //   this.SubscriptionPayment = Subscription["@DownPayment"];
    // }

    this.iceurl = iceurl;
  }

  generateSeating(seats_Array) {
    //  var a = data.Output.Results.ModelAttributes.ModelAttribute;
    if (this.attributes == undefined) {
      // console.log(this.vin);
      return;
    }
    if (this.attributes == null) {
      // console.log(this.vin);
      return;
    }

    var sa = seats_Array;
    //search ModelAttribute [#of Passenger] for this VIN
    for (var i = 0; i < sa.length; i++) {
      var idVal = sa[i]["@id"];
      //5J6RM3H53EL025021
      // if(this.vin =='5J6RM3H53EL025021' && this.dealerId =='206534') { //Scott Robinbson Honda and Honda Pilot
      //     console.log('generateSeating 218');
      //     console.log('seating Array Id - ', idVal);
      // }
      if ($.inArray(idVal, this.attributes) != -1) {
        this.seating = sa[i]["@Value"];
        break;
      }
    }
  }

  // Generate a list of attributes for this instance of car from the list of attributes from the ajax data
  generateFullAttributes(modelAttributeList) {
    if (this.attributes == undefined) {
      // console.log(this.vin);
      return;
    }
    if (this.attributes == null) {
      // console.log(this.vin);
      return;
    }

    for (var i = 0; i < this.attributes.length; i++) {
      var attr = modelAttributeList.getAttributeById2(this.attributes[i]);
      this.fullAttributesArray.push(attr);
      this.fullAttributeObj[attr["@Code"]] = attr;
    }

    if (this.fullAttributeObj["City Fuel Efficiency"] != undefined)
      this.lmpg = this.fullAttributeObj["City Fuel Efficiency"]["@Value"];
    if (this.fullAttributeObj["Highway Fuel Efficiency"] != undefined)
      this.hmpg = this.fullAttributeObj["Highway Fuel Efficiency"]["@Value"];
    if (this.fullAttributeObj["COMB_MPG"] != undefined)
      this.cmpg = this.fullAttributeObj["COMB_MPG"]["@Value"];
    if (this.fullAttributeObj["# of Passengers"] != undefined)
      this.seating = this.fullAttributeObj["# of Passengers"]["@Value"];
    if (this.fullAttributeObj["DRIVE"] != undefined)
      this.drive = this.fullAttributeObj["DRIVE"]["@Value"];
    if (this.fullAttributeObj["Engine Name"] != undefined)
      this.engine = this.fullAttributeObj["Engine Name"]["@Value"];

    return this.fullAttributes;
  }

  // Generate a list of features from model attributes
  generateFeaturesList(modelAttributeList) {
    if (this.attributes == undefined) {
      // console.log(this.vin);
      return;
    }
    if (this.attributes == null) {
      // console.log(this.vin);
      return;
    }
    //  console.log('225',this.attributes.length);

    for (var i = 0; i < this.attributes.length; i++) {
      var attr = modelAttributeList.getAttributeById2(this.attributes[i]);
      var index = this.featureStrMap.indexOf(attr["@Code"]);

      //Special Case For CPLAY
      // if(attr["@Code"]=="CPLAY"){
      //   if(attr["@Value"].toLowerCase()=="true"){
      //     index=this.featureStrMap.indexOf("AAUTO");
      //   }
      // }

      if (index == -1) continue;

      if (
        attr["@Code"] == "INTERIOR MATERIAL" &&
        attr["@Value"].toLowerCase().indexOf("leather") != -1
      ) {
        //this.features.push([this.featureStr[index]]="True");
        this.features.push(this.featureStr[index]);
      }
      // AWD ali
      if (
        attr["@Code"] == "DRIVE" &&
        attr["@Value"].toLowerCase().indexOf("all-wheel") != -1
      ) {
        //this.features.push([this.featureStr[index]]="True");
        this.features.push(this.featureStr[index]);
        // console.log('252: ', this.featureStr[index])
      } else if (attr["@Value"].toLowerCase() == "true") {
        //this.features[this.featureStr[index]]=attr["@Value"];
        this.features.push(this.featureStr[index]);
      }
    }
    //if(this.features["Navigation"]==undefined)this.features["Navigation"]="False";
    //if(this.features["Android Auto / Apple CarPlay"]==undefined)this.features["Android Auto / Apple CarPlay"]="False";
  }

  generateMpgAttributes(modelAttributeList) {
    var context = this;
    var modelAttributes = this.attributes.map(function(attrID) {
      var attributes = [];
      modelAttributeList.map(function(attrData) {
        if (attrData["@id"] === attrID) {
          attributes.push(attrData);
        }
      });
      return attributes;
    });

    modelAttributes.map(function(modelAttr) {
      modelAttr.map(function(aData) {
        if (aData["@Code"] === "City Fuel Efficiency") {
          context.mpg_city = aData["@Value"];
        }
        if (aData["@Code"] === "Highway Fuel Efficiency") {
          context.mpg_highway = aData["@Value"];
        }
        if (aData["@Code"] === "COMB_MPG") {
          context.mpg_combined = aData["@Value"];
        }
      });
    });
  }
}
